"use strict";

/**
 * File popup-init.js.
 *
 */
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", function (e) {
    popupInit();
  });
} else {
  popupInit();
}

function popupInit() {
  var galleries = document.querySelectorAll(".wp-block-gallery");
  galleries.forEach(function (gallery) {
    lightGallery(gallery, {
      selector: ".wp-block-image a"
    });
  });
  var images = document.querySelectorAll(":not(.wp-block-gallery) > .wp-block-image");
  images.forEach(function (image) {
    lightGallery(image, {
      selector: "a"
    });
  });
}